@media screen and (max-width: 3840px) {
    .lifePathBarChart{
        width: 50% !important; 
        min-width: 500px !important;
        height: 400px !important;
        transform: translateX(-3%);
    }

    .lifePathBarChartTitle{
        font-size: 30px;
        margin-top: 50px;
    }

    .pieChartSection{
        display:flex;
        justify-content: center;
        height: 500px !important;
    }

    .pieChartContainer{
        display:flex;
        flex-direction: column;
        align-items: center;
        height: 500px !important;
    }

    .pieChart{
        width: 100% !important; 
        min-width: 300px !important;
    }

    .pieChartTitle{
        transform: translateY(100px);
    }

    .premiumGroup1{
        display:flex;
        flex-direction: column;
        width: 40%;
        min-width: 400px;
    }

    .premiumGroup1-title{
        font-weight: bold;
        font-size: 30px;
    }

    .premiumGroup1-number{
        font-weight: 600;
        margin-left: 10px;
    }

    .premiumGroup1 ul li{
        display: flex;
    }

    .premiumGroup1-click{
        color: blue;
        margin-left: 20px;
        cursor: pointer;
    }

    .premiumGroup1 .btn{
        width: 150px;
        align-self: center;
        font-weight: 500;
    }

    .premiumGroup1 .btn:hover{
        background-color: #ffae00;
    }
    
    .health-ul{
        max-width: 700px;
        list-style: none;
        margin-bottom: 50px;
    }

}


@media screen and (max-width: 540px) {
    .lifePathBarChart{
        width: 100% !important; 
        min-width: auto !important;
        height: 300px !important;
        transform: translateX(-5%);
    }

    .premiumGroup1{
        width: 100%;
        min-width: auto;
    }

    .premiumGroup1-title{
        font-weight: bold;
        font-size: 24px;
    }

    .pieChartSection{
        display:flex;
        flex-direction: column;
        justify-content: center;
        width: 100% !important; 
        height: 500px !important;
    }

    .pieChartContainer{
        display:flex;
        flex-direction: column;
        align-items: center;
        height: 250px !important;
    }

    .health-ul{
        box-sizing: border-box;
        max-width: 500px;
        list-style: none;
        margin-bottom: 50px;
        padding: 10px 10px
    }
}