.direction-container{
    width: 400px;
    height: 400px;
    display:flex;
    position: relative;
}

.direction-background{
    width: 100%;
    height: 100%;
}

.direction-container h4{
    color: #FFBD59;
    font-size: 30px;
    text-shadow: 0px 0px 2px black;
    position:absolute;
}

.direction-good{
    color: #09ff00 !important;
}

.direction-one{
    top: 9%;
    left: 53%;
}

.direction-two{
    top: 23%;
    left: 79%;
}

.direction-three{
    top: 52%;
    left: 85%;
}

.direction-four{
    top: 75%;
    left: 70%;
}

.direction-five{
    top: 82%;
    left: 44%;
}

.direction-six{
    top: 68%;
    left: 19%;
}

.direction-seven{
    top: 40%;
    left: 10%;
}

.direction-eight{
    top: 15%;
    left: 25%;
}

@media screen and (max-width: 540px) {
    .direction-container{
        width: 100%;
        height: 400px;
        display:flex;
        position: relative;
    }
}