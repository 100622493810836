@media screen and (max-width: 3840px) {
    .top-content-container{
        display: flex;
        box-sizing: border-box;
        padding: 0px 0px;
        margin-left: 15vw;
        margin-top: 5vh;
    }

    .top-content-text{
        margin-left: 15vw;
        margin-top: 5vh;
    }

    .top-content{
        height: 100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 50px;
        cursor: pointer;
    }

    .top-content img{
        height: 60px;
    }

    .top-content p{
        margin-bottom: 0px;
    }

    .middle-content-container{
        display: flex;
        margin-top: 5vh;
        box-sizing: border-box;
        padding: 0px 0px;
        margin-left: 15vw;
    }

    .middle-content{
        height: 100%;
        display:flex;
        flex-direction: column;
        margin-right: 20px;
        cursor: pointer;
        border-radius: 10px;
        transition: 0.3s ease-out;
    }

    .middle-content:hover {
        color: #022452;
        box-shadow: 0 0 5px #022452;
        text-shadow: 0 0 5px #022452;
    }

    .middle-content img{
        width: 33vw;
        border-radius: 10px;
    }

    .bottom-content-title{
        margin-top: 5vh;
        margin-left: 15vw;
    }

    .bottom-content-container{
        display: flex;
        box-sizing: border-box;
        padding: 0px 0px;
        margin-left: 15vw;
    }

    .bottom-content{
        height: 100%;
        display:flex;
        flex-direction: column;
        margin-right: 20px;
        cursor: pointer;
        transition: 0.3s ease-out;
    }

    .bottom-content:hover {
        color: #022452;
        box-shadow: 0 0 5px #022452;
        text-shadow: 0 0 5px #022452;
    }

    .bottom-content img{
        width: 33vw;
        border-radius: 10px;
    }

    .page-content-title{
        margin-top: 20px;
        margin-left: 20px;
        font-size: 40px;
    }

    .page-title{
        margin-top: 5vh;
    }

    .page-title-description{
        margin-top: 1vh;
    }

    .book-image{
        width: 50%;
        border-radius: 10px;
        margin-top: 20px;
        max-width: 400px;
    }

    .facebook-image{
        width: 100%;
        border-radius: 10px;
        margin-top: 20px;
        max-width: 400px;
    }
}

@media screen and (max-width: 540px) {
    .top-content-container{
        display: flex;
        margin-top: 30px;
        box-sizing: border-box;
        padding: 0px 0px;
        margin-left: 0;
    }

    .top-content-text{
        display: none;
    }

    .top-content{
        height: 100%;
        display:flex;
        flex-direction: column;
        margin-left: 20px;
        justify-content: center;
        align-items: center;
        margin-right: 0px;
    }

    .top-content img{
        height: 60px;
    }

    .top-content p{
        margin-bottom: 0px;
    }

    .middle-content-container{
        display: flex;
        margin-top: 20px;
        box-sizing: border-box;
        padding: 0px 0px;
        margin-left: 0;
    }

    .middle-content{
        height: 100%;
        display:flex;
        flex-direction: column;
        margin-left: 20px;
        
    }

    .middle-content img{
        width: 40vw;
        border-radius: 10px;
    }

    .bottom-content-title{
        margin-top: 20px;
        margin-left: 20px;
    }

    .bottom-content-container{
        display: flex;
        box-sizing: border-box;
        padding: 0px 0px;
        margin-left: 0;
    }

    .bottom-content{
        height: 100%;
        display:flex;
        flex-direction: column;
        margin-left: 20px;
    }

    .bottom-content img{
        width: 40vw;
        border-radius: 10px;
    }

    .page-content-title{
        margin-top: 20px;
        margin-left: 20px;
        font-size: 24px;
    }

    .page-title{
        margin-top: 20px;
        margin-left: 20px;
    }

    .page-title-description{
        margin-top: 20px;
        margin-left: 20px;
    }

    .book-image{
        width: 80%;
        border-radius: 10px;
        margin-top: 20px;
    }

    .facebook-image{
        width: 60%;
        border-radius: 10px;
        margin-top: 20px;
    }

}