@media screen and (max-width: 3840px){
    .footer-container{
        display: none;
    }

    .footer-copyright{
        display: flex;
        background-color: #FFBD59;
        margin-top: 10vh;
        align-items: center;
        height: 50px;
    }

    .footer-copyright p{
        margin-bottom: 0px;
        margin-left: 20px;
    }
}

@media screen and (max-width: 540px) {

    .footer-container{
        display: block;
        position: fixed;
        bottom:0;
        width: 100%;
        height: 70px;
    }

    .footer-container p {
        color: var(--footer-button-text-color);
        margin: 0px;
    }

    .footer-background{
        background: #FFBD59;
        height: 80px;
        width: 100%;
        background-position: center;
    }

    .footer-button1{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 3%;
        border-radius: 40px;
        width: 50px;
        height: 50px;
        transition: all 1s;
        transform: translateY(-13%);
    }

    .footer-button1 img{
        width: 100%;
        height: 100%;
        margin-bottom: 3px;
    }

    .footer-button2{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 23%;
        border-radius: 40px;
        width: 50px;
        height: 50px;
        transition: all 1s;
        transform: translateY(-7%);
    }

    .footer-button2 img{
        width: 100%;
        height: 100%;
    }

    .home-button-wrapper{
        position: absolute;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%,-30%);
        transition: all 1s;
        box-shadow: 0 0 10px #fff6;
    }

    .home-button-wrapper img{
        width: 80px;
        height: 80px;
    }

    .footer-button3{
        width: 75px;
        height: 75px;
    }

    .footer-button4{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        right: 23%;
        border-radius: 40px;
        width: 50px;
        height: 50px;
        transition: all 1s;
        transform: translateY(-7%);
    }

    .footer-button4 img{
        width: 50px;
        height: 50px;
    }

    .footer-button5{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        right: 3%;
        border-radius: 40px;
        width: 50px;
        height: 50px;
        transition: all 1s;
        transform: translateY(-7%);
    }

    .footer-button5 img{
        width: 50px;
        height: 50px;
    }

    .footer-copyright{
        display: none;
    }
}