@media screen and (max-width: 3840px) {
  
  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  body{
    /* background-image: url('./assets/lightbackground.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; */
    background:white;
    font-family: "Kanit", sans-serif !important;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  .react-datepicker__year-select{
    border:none;
    font-size: 16px;
    font-weight: 300;
    padding: 3px 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .react-datepicker__month-select{
    border:none;
    font-size: 16px;
    font-weight: 300;
  }

  .main-layout{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
  }

  .triangle-layout{
    display:flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
  }

  .triangle-background{
    position: absolute;
    top: 10px;
    width: 250px;
  }

  .triangle-data-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }

  .triangle-data-container h1{
    margin-bottom: 0px;
    font-size: 40px;
  }

  .triangle-first-line{
    display:flex;
  }

  .triangle-first-left{
    display:flex;
    margin-right: 10px;
  }

  .triangle-first-right{
    display:flex;
    margin-left: 10px;
  }

  .triangle-second-line{
    display:flex;
    margin-top: 15px;
  }

  .triangle-second-left{
    margin-right: 15px;
  }

  .triangle-seven-number{
    font-size: 40px;
    color: white !important;
    margin-top: 16px;
    margin-bottom: 0px;
  }

  .triangle-second-right{
    margin-left: 15px;
  }

  .triangle-third-line{
    display:flex;
    transform:translateY(-10px);
  }

  .triangle-third-left{
    margin-right: 50px;
    display:flex;
  }

  .triangle-third-right{
    margin-left: 50px;
    display:flex;
  }

  .triangle-forth-line{
    display: flex;
    transform:translateY(-10px);
  }

  .triangle-number-forth-left{
    margin-right: 40px;
  }

  .triangle-number-forth-right{
    margin-left: 40px;
  }

  .triangle-fift-line{
    transform: translateY(-10px);
  }

  .triangle-number{
    margin: 0px 10px;
  }

  .main-content-container{
    padding-top: 120px;
    padding-left: 15vw;
    padding-right: 15vw;
    position: relative;
    z-index: 1;
  }

  .page-content-container{
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
    display:flex;
    flex-direction: column;
    align-items: center;
    min-height: 85vh;
  }

  .page-content-container h1, h2, h3{
    color: #022452 !important;
  }

  .background-element{
    padding-bottom: 0px;
  }

  .carousel-root {
    overflow: visible;
    background: none;
    width: 100%;
    z-index: 1;
    margin-bottom: 5px;
  }

  .carousel.carousel-slider{
    overflow: visible !important;
  }

  .carousel .slider-wrapper{
    border-radius: 10px;
  }

  .carousel .slide {
    z-index: 1;
  }

  .carousel .control-dots{
    bottom: -35px !important;
  }

  .dot{
    background: #FFBD59 !important;
  }

  .modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dark semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content{
    background-color: #FFBD59 !important;
    transform: translateY(20%);
  }

  .modal-header{
    background-color: #022452 !important;
    color: white;
  }

  .btn-secondary{
    background-color: #022452 !important;
    color: white;
  }

  .btn-danger.active{
    background-color: #022452 !important;
    border: solid 1px #FFBD59;
    box-shadow: 0px 0px 4px black;
  }

  .form-container{
    width: 50%;
    min-width: 500px;
  }

  .error-message{
    color:red !important;
    font-size: 30px;
    text-shadow: 0px 0px 1px black;
  }
}

@media screen and (max-width: 540px) {
  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  body{
    /* background-image: url('./assets/lightbackground.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; */
    background:white;
    font-family: "Kanit", sans-serif !important;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  .react-datepicker__year-select{
    border:none;
    font-size: 16px;
    font-weight: 300;
    padding: 3px 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .react-datepicker__month-select{
    border:none;
    font-size: 16px;
    font-weight: 300;
  }

  .main-layout{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
  }

  .triangle-layout{
    display:flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
  }

  .triangle-background{
    position: absolute;
    top: 10px;
  }

  .triangle-data-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }

  .triangle-data-container h1{
    margin-bottom: 0px;
  }

  .triangle-first-line{
    display:flex;
  }

  .triangle-first-left{
    display:flex;
    margin-right: 8px;
  }

  .triangle-first-right{
    display:flex;
    margin-left: 8px;
  }

  .triangle-second-line{
    display:flex;
    margin-top: 15px;
  }

  .triangle-second-left{
    margin-right: 15px;
  }

  .triangle-second-right{
    margin-left: 15px;
  }

  .triangle-third-line{
    display:flex;
    transform:translateY(-10px);
  }

  .triangle-third-left{
    margin-right: 30px;
    display:flex;
  }

  .triangle-third-right{
    margin-left: 30px;
    display:flex;
  }

  .triangle-forth-line{
    display: flex;
    transform:translateY(-10px);
  }

  .triangle-number-forth-left{
    margin-right: 25px;
  }

  .triangle-number-forth-right{
    margin-left: 25px;
  }

  .triangle-fift-line{
    transform: translateY(-10px);
  }

  .triangle-number{
    margin: 0px 10px;
  }

  .main-content-container{
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    z-index: 1;
  }

  .page-content-container{
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 80px;
    display:flex;
    flex-direction: column;
    align-items: center;
    min-height: auto;
  }

  .background-element{
    padding-bottom: 80px;
  }

  .carousel-root {
    overflow: visible;
    background: none;
    width: 100%;
    z-index: 1;
    margin-bottom: 5px;
  }

  .carousel.carousel-slider{
    overflow: visible !important;
  }

  .carousel .slider-wrapper{
    border-radius: 10px;
  }

  .carousel .slide {
    z-index: 1;
  }

  .carousel .control-dots{
    bottom: -35px !important;
  }

  .dot{
    background: #FFBD59 !important;
  }

  .modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dark semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content{
    background-color: #FFBD59 !important;
    transform: translateY(20%);
  }

  .modal-header{
    background-color: #022452 !important;
    color: white;
  }

  .btn-secondary{
    background-color: #022452 !important;
    color: white;
  }

  .btn-danger.active{
    background-color: #022452 !important;
    border: solid 1px #FFBD59;
    box-shadow: 0px 0px 4px black;
  }

  .form-container{
    width: 90%;
    min-width: 0;
  }

  .page-text{
    font-size: 22px;
    margin-top: 20px;
  }

  .error-message{
    color:red !important;
    text-shadow: 0px 0px 1px black;
    font-size: 24px;
  }
}