@media screen and (max-width: 3840px) {
        
    .login-modal-container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100000;
        width: 50%;
        max-width: 500px;
        min-height: 408px;
        border-radius: 10px;
        background: #FFBD59;
    }

    .login-header-container{
        position:absolute;
        top:0%;
        width: 100%;
    }

    .login-header-button{
        width:50%;
        min-height: 80px;
        border: none;
        background: #353535;
        font-size: 24px;
        font-family: 'Kanit';
        color: white;
    }

    .login-header-button-active{
        width:50%;
        min-height: 80px;
        border: none;
        background: #1B1B1B;
        font-size: 24px;
        font-family: 'Kanit';
        color: white;
    }

    .login-modal-content{
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }

    .login-username-input{
        margin: 10px 0px;
        line-height: 2.5em;
        z-index: 10000;
        padding-left: 10px;
        text-align: center !important;
    }

    .login-modal-content h1{
        color: #022452;
        text-shadow: 1px 1px 2px #000000;
    }

    .login-modal-content img{
        cursor: pointer
    }

    .login-button-close{
        position: absolute;
        right: 0px;
        top: -40px;
        border-radius: 13px;
        width: 26px;
        height: 26px;
        border:none;
        box-shadow: 1px 1px 4px 0 #000000;
        background: #022452;
        font-size: 26px;
        text-align: center;
        line-height: 00%;
        color:#FFBD59;
        padding-bottom:5px;
    }

    .login-modal-no-account-container{
        display: flex;
        margin-top: 10px;
    }

    .login-modal-no-account-text{
        margin: 0;
        color: var(--menu-text-color);
    }

    .login-modal-no-account-link{
        color: #DAA520;
        margin-left : 10px;
        font-weight: bold;
        text-decoration: underline;
    }

    .login-error-message{
        color: red;
        font-weight: bold;
        font-size: 16px;
        text-shadow: 1px 1px 4px #000000;
        margin: 0px;
    }

    .login-button{
        position: absolute;
        bottom:0px;
        width: 100%;
        background: radial-gradient(100% 100% at 50% 0,#fff2ca 16.67%,#bda362 61.98%,#977525 100%);
        font-family: 'Kanit';
        font-size: 16px;
        height: 50px;
    }

    .login-input-container{
        position:relative;
        width: 100%;
    }

    .login-button-image{
        position: absolute;
        width: 20px;
        height: 20px;
        z-index: 10001;
        left: 10px;
        top: 1.7em;
    }

    .ant-input[type="password"]{
        text-align: center;
        margin-left: 15px;
    }
}

@media screen and (max-width: 540px) {
        
    .login-modal-container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100000;
        min-width: 80%;
        min-height: 408px;
        border-radius: 10px;
        background: #FFBD59;
    }

    .login-header-container{
        position:absolute;
        top:0%;
        width: 100%;
    }

    .login-header-button{
        width:50%;
        min-height: 80px;
        border: none;
        background: #353535;
        font-size: 24px;
        font-family: 'Kanit';
        color: white;
    }

    .login-header-button-active{
        width:50%;
        min-height: 80px;
        border: none;
        background: #1B1B1B;
        font-size: 24px;
        font-family: 'Kanit';
        color: white;
    }

    .login-modal-content{
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }

    .login-username-input{
        margin: 10px 0px;
        line-height: 2.5em;
        z-index: 10000;
        padding-left: 10px;
        text-align: center !important;
    }

    .login-modal-content h1{
        color: #022452;
        text-shadow: 1px 1px 2px #000000;
    }

    .login-button-close{
        position: absolute;
        right: 20px;
        top: -40px;
        border-radius: 13px;
        width: 26px;
        height: 26px;
        border:none;
        box-shadow: 1px 1px 4px 0 #000000;
        background: #022452;
        font-size: 26px;
        text-align: center;
        line-height: 00%;
        color:#FFBD59;
        padding-bottom:5px;
    }

    .login-modal-no-account-container{
        display: flex;
        margin-top: 10px;
    }

    .login-modal-no-account-text{
        margin: 0;
        color: var(--menu-text-color);
    }

    .login-modal-no-account-link{
        color: #DAA520;
        margin-left : 10px;
        font-weight: bold;
        text-decoration: underline;
    }

    .login-error-message{
        color: red;
        font-weight: bold;
        font-size: 16px;
        text-shadow: 1px 1px 4px #000000;
        margin: 0px;
    }

    .login-button{
        position: absolute;
        bottom:0px;
        width: 100%;
        background: radial-gradient(100% 100% at 50% 0,#fff2ca 16.67%,#bda362 61.98%,#977525 100%);
        font-family: 'Kanit';
        font-size: 16px;
        height: 50px;
    }

    .login-input-container{
        position:relative;
        width: 100%;
    }

    .login-button-image{
        position: absolute;
        width: 20px;
        height: 20px;
        z-index: 10001;
        left: 10px;
        top: 1.7em;
    }

    .ant-input[type="password"]{
        text-align: center;
        margin-left: 15px;
    }
}