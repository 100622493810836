@media screen and (max-width: 3840px) {

    .desktop-nav{
        display: flex;
        position: absolute;
        right: 125px;
        justify-content: center;
        align-items: center;
        margin-left: 50px;
    }

    .desktop-nav-link{
        margin: 0px 20px 0px 20px;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
    }
        
    .header-with-menu{
        z-index: 1000;
        position:fixed;
        left: 0px;
    }
    
    .nav-container{
        position:fixed;
        width: 100%;
        height: 70px;
    }
    
    .navbar {
        background-color: #FFBD59;
        display:flex;
        height: 100%;
        align-items: center;
        position: sticky; /* Use fixed to make the navbar follow when scrolling */
        z-index: 1000; /* Ensure it stays above other content */
        color: #022452;
        border-bottom: var(--nav-border-bottom);
        border-bottom-right-radius: var(--nav-right-radius);
        border-bottom-left-radius: var(--nav-left-radius);
        box-shadow: var(--nav-box-shadow);
        justify-content: flex-start !important;
    }

    .nav-profile-logo{
        height: 100%;
        margin-left: 10px;
    }

    .profile-logo{
        font-size: 40px;
    }

    .header-logo-flex{
        display:flex;
        align-items: center;
        height: 100%;
        margin-left: 50px;
        cursor: pointer;
    }
    
    .menu-icon {
        font-size: 28px;
        color: #fff;
        padding: 0 15px;
        cursor: pointer;
    }
    
    .header-text {
        color: #fff;
        font-size: 18px;
    }

    .header-logo-flex h1{
        margin-top: auto;
        margin-bottom: auto;
    }
    
    .nav-login-button{
        font-family: "Kanit", sans-serif !important;
        width: 95px;
        height: 33px;
        color: #FFBD59;
        background: #022452;
        border: none;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        box-shadow: 0px 0px 2px black;
        margin-right: 10px;
        position: absolute;
        right: 20px;
    }

    .nav-logout-button{
        font-family: "Kanit", sans-serif !important;
        width: 40px;
        height: 40px;
        color: #FFBD59;
        background: #022452;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        font-size: 16px;
        box-shadow: 0px 0px 2px black;
        margin-right: 10px;
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .nav-logout-button img{
        height: 80%;
    }

    .header-logo{
        height: 100%;
    }

    .nav-language-dropdown img{
        margin-left: 30px;
        display: flex;
        position: relative;
    }

    .custom-dropdown {
        position: relative;
        display: inline-block;
    }
    
    .dropdown-selected {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    
    .language-icon {
        width: 48px;
        height: 48px;
    }
    
    .custom-dropdown {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 50px;
        height: 50px;
        z-index: 10000;
    }
    
    .custom-dropdown li {
        margin-top: 11px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    
    .header-logout-button{
        width: 40px;
        height: 40px;
    }

    .header-logout-section{
        display: flex;
        position:absolute;
        align-items: center;
        right: 0px;
    }

    .header-profile-button{
        display: block;
        height: 50px;
        width: 50px;
        margin-right: 10px;
        cursor: pointer;
    }
}

@media screen and (max-width: 1250px){
    .desktop-nav{
        display: none;
    }
}

@media screen and (max-width: 540px) {

    .header-with-menu{
        z-index: 1000;
        position:fixed;
        left: 0px;
    }
    
    .nav-container{
        position:fixed;
        width: 100%;
        height: 70px;
    }
    
    .navbar {
        background-color: #FFBD59;
        display:flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: sticky; /* Use fixed to make the navbar follow when scrolling */
        z-index: 1000; /* Ensure it stays above other content */
        color: #022452;
        border-bottom: var(--nav-border-bottom);
        border-bottom-right-radius: var(--nav-right-radius);
        border-bottom-left-radius: var(--nav-left-radius);
        box-shadow: var(--nav-box-shadow);
    }

    .nav-profile-logo{
        height: 100%;
        margin-left: 10px;
    }

    .profile-logo{
        font-size: 40px;
    }

    .header-logo-flex{
        display:flex;
        align-items: center;
        height: 100%;
        margin: 0px;
    }
    
    .menu-icon {
        font-size: 28px;
        color: #fff;
        padding: 0 15px;
        cursor: pointer;
    }
    
    .header-text {
        color: #fff;
        font-size: 18px;
    }

    .header-logo-flex h1{
        margin-top: auto;
        margin-bottom: auto;
    }
    
    .nav-login-button{
        font-family: "Kanit", sans-serif !important;
        width: 70px;
        height: 33px;
        color: #FFBD59;
        background: #022452;
        border: none;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        box-shadow: 0px 0px 2px black;
        margin-right: 0px;
    }

    .nav-logout-button{
        font-family: "Kanit", sans-serif !important;
        width: 40px;
        height: 40px;
        color: #FFBD59;
        background: #022452;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        font-size: 16px;
        box-shadow: 0px 0px 2px black;
        margin-right: 10px;
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .nav-logout-button img{
        height: 80%;
    }

    .header-logo{
        height: 100%;
    }

    .nav-language-dropdown img{
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        position: relative;
    }

    .custom-dropdown {
        position: relative;
        display: inline-block;
    }
    
    .dropdown-selected {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    
    .language-icon {
        width: 40px;
        height: 40px;
    }
    
    .custom-dropdown {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 50px;
        height: 50px;
        z-index: 10000;
    }
    
    .custom-dropdown li {
        margin-top: 11px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    
    .header-logout-button{
        width: 40px;
        height: 40px;
    }

    .header-profile-button{
        display: none;
    }
}
